<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      finish-button-text="Confirmar"
      next-button-text="Siguiente"
      back-button-text="Regresar"
      class="wizard-vertical mb-3"
      shape="square"
      step-size="sm"
      @on-complete="formSubmitted"
    >
      <!-- Variant -->
      <tab-content title="Variante del curso" :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Ingresa el nombre de la variante (Ej. Modalidad, Nivel, Periodo,
                etc.)
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nombre de la variante" label-for="variants">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre de la variante"
                  rules="required"
                >
                  <b-form-input
                    id="variants"
                    v-model="variant.name"
                    autofocus
                    placeholder="Modalidad"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Variant Options -->
      <tab-content
        title="Opciones de la variante"
        :before-change="validateVariantOptions"
      >
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Ingresa las opciones para la variante (Ej. Online, Presencial,
              Periodos, etc.)
            </h5>
          </b-col>
        </b-row>
        <div
          v-for="(option, index) in optionVariants"
          :key="index"
          class="mb-1 d-flex align-items-center"
        >
          <div>
            <b-form-input
              :id="'variant-info-' + index"
              v-model="option.option_name"
              autofocus
              :state="
                option.option_name === ''
                  ? setStateToFalse()
                  : setButtonsValue()
              "
              trim
              placeholder="Online"
              size="lg"
              type="text"
            />
            <b-form-invalid-feedback>
              {{ variantOptionsError }}
            </b-form-invalid-feedback>
          </div>
          <b-button
            class="ml-50"
            variant="outline-warning"
            size="sm"
            @click="removeVariantOption(index)"
          >
            <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5"></feather-icon>
          </b-button>
        </div>

        <b-button
          v-if="showAddOptionButton"
          variant="primary"
          class="mt-1"
          @click="addVariantOption"
        >
          Agregar opción
        </b-button>
      </tab-content>

      <!-- Resumen de información -->
      <tab-content title="Confirmar">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5 class="mb-0">Resumen de nueva variante</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="4" class="mb-2">
            <p>
              <b-row>
                <b-col>
                  <p class="mb-1 mt-1">Nombre de la variante:</p>
                  <p class="text-warning">
                    {{ this.variant.name }}
                  </p>
                </b-col>
              </b-row>
            </p>

            <p>
              <b-row>
                <b-col>
                  <p class="mb-1 mt-1">Opciones de la variante:</p>
                  <p class="text-warning">
                    {{
                      this.optionVariants
                        .map((option) => option.option_name)
                        .join(", ")
                    }}
                  </p>
                </b-col>
              </b-row>
            </p>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { FormWizard, TabContent } from "vue-form-wizard"
import vSelect from "vue-select"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from "@validations"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import "@/@core/scss/vue/libs/vue-wizard.scss"
import BaseCropper from "@/@core/components/BaseCropper.vue"
import AddStoreProducts from "@/views/stores/AddStoreProductsToAutomaticRestockingSearchBar.vue"
import RestockingProductsCart from "@/views/stores/RestockingProductsCart.vue"
import router from "@/router"
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
} from "bootstrap-vue"
import Cleave from "vue-cleave-component"
import { avatarText } from "@core/utils/filter"
import { handleAPIErrors } from "@/@core/utils/fetch-utils"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BaseCropper,
    AddStoreProducts,
    avatarText,
    RestockingProductsCart,
  },

  setup() {
    return {
      // Filter
      avatarText,
    }
  },

  data() {
    return {
      showAddOptionButton: false,
      showRemoveOptionButton: false,
      variantOptionsError: "",
      required,
      variant: {
        name: "",
        establishment_id: null,
        variant_options_attributes: [],
      },

      optionVariants: [{ option_name: "" }],
    }
  },

  computed: {
    ...mapGetters("establishments", ["currentEstablishment"]),
    ...mapGetters("variants", ["getStoreId"]),
  },

  methods: {
    ...mapActions("variants", ["createVariant"]),

    addVariantOption() {
      this.optionVariants.push({ option_name: "" })
      this.showAddOptionButton = false
      this.showRemoveOptionButton = true
    },

    removeVariantOption(index) {
      this.optionVariants.splice(index, 1)
      this.showAddOptionButton = true
      this.showRemoveOptionButton = true
    },

    setButtonsValue() {
      const isAtLeastOneOptionProvided = this.optionVariants.some(
        (option) => option.option_name.trim() !== ""
      )
      const areAllOptionsProvided = this.optionVariants.every(
        (option) => option.option_name.trim() !== ""
      )
      const hasMoreThanOneOption = this.optionVariants.length > 1

      if (areAllOptionsProvided && hasMoreThanOneOption) {
        this.showAddOptionButton = true
        this.showRemoveOptionButton = true
        return null
      }

      if (isAtLeastOneOptionProvided && hasMoreThanOneOption) {
        this.showAddOptionButton = true
        this.showRemoveOptionButton = true
        return null
      }

      if (isAtLeastOneOptionProvided) {
        this.showAddOptionButton = true
        this.showRemoveOptionButton = false
        return null
      }
    },

    setStateToFalse() {
      this.variantOptionsError = "El campo no puede estar vacío"
      return false
    },

    formSubmitted() {
      this.variant.store_id = this.$route.params.id
      this.variant.variant_options_attributes = this.optionVariants

      this.createVariant(this.variant)
        .then(() => {
          this.$swal({
            text: "Se ha creado la variante existosamente!",
            title: "Variante creada!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
          this.$router.push({
            name: "store-variants",
            params: { id: this.getStoreId },
          })
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)

          this.$swal({
            text: errors[0],
            title: "Algo salió mal!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validateVariantOptions() {
      // Check if at least one option.value is provided
      const isAtLeastOneOptionProvided = this.optionVariants.some(
        (option) => option.option_name.trim() !== ""
      )

      // Show error message if not
      if (!isAtLeastOneOptionProvided) {
        this.variantOptionsError = "El campo no puede estar vacío"
        return false
      }
      return true
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.promo-card {
  height: 120px;
}

.custom-form-group {
  width: 60%; /* Set the desired width for the input fields */
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}

.btn-size {
  width: 100%;
  height: 60px;
  margin-right: 40px; /* Adjust the margin as needed */
  transition: background-color 0.7s ease; /* Smooth transition for color change */
}

.btn-size.active {
  background-color: #28a745 !important; /* Green color for the success variant */
}
</style>
